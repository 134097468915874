import { h } from "vue";
import type { IconSet, IconAliases, IconProps } from "vuetify";

const aliases: IconAliases = {
  calendar: "icon-calendar", // calendar: mdi-calendar
  cancel: "icon-circle-x", // cancel: mdi-close-circle
  checkboxIndeterminate: "icon-square-minus", // checkboxIndeterminate: mdi-minus-box
  checkboxOff: "icon-square", // checkboxOff: mdi-checkbox-blank-outline
  checkboxOn: "icon-square-check", // checkboxOn: mdi-checkbox-marked
  clear: "icon-x", // clear: mdi-close-circle
  close: "icon-x", // close: mdi-close
  collapse: "icon-chevron-up", // collapse: mdi-chevron-up
  complete: "icon-check", // complete: mdi-check
  delete: "icon-circle-x", // delete: mdi-close-circle
  delimiter: "icon-circle", // delimiter: mdi-circle
  dropdown: "icon-chevron-down", // dropdown: mdi-menu-down
  edit: "icon-pencil", // edit: mdi-pencil
  error: "icon-circle-x", // error: mdi-close-circle
  expand: "icon-chevron-down", // expand: mdi-chevron-down
  eyeDropper: "icon-chevron-down", // eyeDropper: mdi-eyedropper
  file: "icon-paperclip", // file: mdi-paperclip
  first: "icon-arrow-left-to-line", // first: mdi-page-first
  info: "icon-info", // info: mdi-information
  last: "icon-arrow-right-to-line", // last: mdi-page-last
  loading: "icon-refresh-cw", // loading: mdi-cached
  menu: "icon-menu", // menu: mdi-menu
  minus: "icon-minus", // minus: mdi-minus
  next: "icon-chevron-right", // next: mdi-chevron-right
  plus: "icon-plus", // plus: mdi-plus
  prev: "icon-chevron-left", // prev: mdi-chevron-left
  radioOff: "icon-circle", // radioOff: mdi-radiobox-blank
  radioOn: "icon-circle-check", // radioOn: mdi-radiobox-marked
  ratingEmpty: "icon-star-off", // ratingEmpty: mdi-star-outline
  ratingFull: "icon-star", // ratingFull: mdi-star
  ratingHalf: "icon-star-half", // ratingHalf: mdi-star-half-full
  sortAsc: "icon-arrow-up", // sortAsc: mdi-arrow-up
  sortDesc: "icon-arrow-down", // sortDesc: mdi-arrow-down
  subgroup: "icon-corner-right-down", // subgroup: mdi-menu-down
  success: "icon-circle-check", // success: mdi-check-circle
  unfold: "icon-chevrons-up-down", // unfold: mdi-unfold-more-horizontal
  warning: "icon-circle-alert" // warning: mdi-alert-circle
};

const icon: IconSet = {
  component: (props: IconProps) =>
    h("i", {
      ...props,
      class: props.icon
    })
};

export { aliases, icon };

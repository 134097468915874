// import this after install `@mdi/font` package

import "vuetify/styles";
import { aliases, icon } from "../iconsets/lucide-iconset";
import { createVuetify, type ThemeDefinition } from "vuetify";

export default defineNuxtPlugin((app) => {
  const toonoTheme: ThemeDefinition = {
    dark: false,
    colors: {
      primary: "#138EFF",
      secondary: "#303030",
      error: "#F64669",
    }
  };
  const vuetify = createVuetify({
    ssr: true,
    defaults: {
      VBtn: {
        style: "text-transform: none; letter-spacing: 0.02em;"
      }
    },
    theme: {
      defaultTheme: "toonoTheme",
      themes: {
        toonoTheme
      }
    },
    icons: {
      defaultSet: "icon",
      aliases,
      sets: {
        icon
      }
    }
  });
  app.vueApp.use(vuetify);
});

export const useBanners = () => {
    return useState("banners", () => null)
}

export const useFetchBanners = async () => {
    const banners = useBanners()
    const { data: c, error } = await useFetch("/pub/banners", {
        query: {
            status: "active",
        }
    })
    if (error.value) {
        banners.value = null
    }
    let bs = {}
    if (c.value.items?.length) {
        for (let b of c.value.items) {
            if (bs[b.location]) {
                bs[b.location].push(b)
            } else {
                bs[b.location] = [b]
            }
        }
    }
    banners.value = bs
}

import { default as account_45delete_45instruction4cbojM1z86Meta } from "/home/orgio/projects/ad-portal/frontend/pages/account-delete-instruction.vue?macro=true";
import { default as _91id_93RyWk4gGWXnMeta } from "/home/orgio/projects/ad-portal/frontend/pages/ad/[id].vue?macro=true";
import { default as _91category_937goLMtSMSfMeta } from "/home/orgio/projects/ad-portal/frontend/pages/add/[category].vue?macro=true";
import { default as indexmgcSgv0XGuMeta } from "/home/orgio/projects/ad-portal/frontend/pages/add/index.vue?macro=true";
import { default as banner_45ad1nyBlP7NyJMeta } from "/home/orgio/projects/ad-portal/frontend/pages/banner-ad.vue?macro=true";
import { default as addr2GRVIrz7yMeta } from "/home/orgio/projects/ad-portal/frontend/pages/banners/add.vue?macro=true";
import { default as indexDh37q8uX7vMeta } from "/home/orgio/projects/ad-portal/frontend/pages/banners/index.vue?macro=true";
import { default as bannersCokovGSwVQMeta } from "/home/orgio/projects/ad-portal/frontend/pages/banners.vue?macro=true";
import { default as _91id_93RbrEqeLW3qMeta } from "/home/orgio/projects/ad-portal/frontend/pages/edit/[id].vue?macro=true";
import { default as faq8k4SOvCxxfMeta } from "/home/orgio/projects/ad-portal/frontend/pages/faq.vue?macro=true";
import { default as indexfvWSwy5au8Meta } from "/home/orgio/projects/ad-portal/frontend/pages/index.vue?macro=true";
import { default as privacy_45policyn06zuJUBoDMeta } from "/home/orgio/projects/ad-portal/frontend/pages/privacy-policy.vue?macro=true";
import { default as indexbaMhNVwJKMMeta } from "/home/orgio/projects/ad-portal/frontend/pages/profile/index.vue?macro=true";
import { default as likedxP0uXOxLVBMeta } from "/home/orgio/projects/ad-portal/frontend/pages/profile/liked.vue?macro=true";
import { default as my_45ads81PUvuw1lCMeta } from "/home/orgio/projects/ad-portal/frontend/pages/profile/my-ads.vue?macro=true";
import { default as profileHSfglt6Qs5Meta } from "/home/orgio/projects/ad-portal/frontend/pages/profile.vue?macro=true";
import { default as searchureOsyd8JAMeta } from "/home/orgio/projects/ad-portal/frontend/pages/search.vue?macro=true";
import { default as termsHY2OcZu6z6Meta } from "/home/orgio/projects/ad-portal/frontend/pages/terms.vue?macro=true";
export default [
  {
    name: account_45delete_45instruction4cbojM1z86Meta?.name ?? "account-delete-instruction",
    path: account_45delete_45instruction4cbojM1z86Meta?.path ?? "/account-delete-instruction",
    meta: account_45delete_45instruction4cbojM1z86Meta || {},
    alias: account_45delete_45instruction4cbojM1z86Meta?.alias || [],
    redirect: account_45delete_45instruction4cbojM1z86Meta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/account-delete-instruction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RyWk4gGWXnMeta?.name ?? "ad-id",
    path: _91id_93RyWk4gGWXnMeta?.path ?? "/ad/:id()",
    meta: _91id_93RyWk4gGWXnMeta || {},
    alias: _91id_93RyWk4gGWXnMeta?.alias || [],
    redirect: _91id_93RyWk4gGWXnMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/ad/[id].vue").then(m => m.default || m)
  },
  {
    name: _91category_937goLMtSMSfMeta?.name ?? "add-category",
    path: _91category_937goLMtSMSfMeta?.path ?? "/add/:category()",
    meta: _91category_937goLMtSMSfMeta || {},
    alias: _91category_937goLMtSMSfMeta?.alias || [],
    redirect: _91category_937goLMtSMSfMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/add/[category].vue").then(m => m.default || m)
  },
  {
    name: indexmgcSgv0XGuMeta?.name ?? "add",
    path: indexmgcSgv0XGuMeta?.path ?? "/add",
    meta: indexmgcSgv0XGuMeta || {},
    alias: indexmgcSgv0XGuMeta?.alias || [],
    redirect: indexmgcSgv0XGuMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/add/index.vue").then(m => m.default || m)
  },
  {
    name: banner_45ad1nyBlP7NyJMeta?.name ?? "banner-ad",
    path: banner_45ad1nyBlP7NyJMeta?.path ?? "/banner-ad",
    meta: banner_45ad1nyBlP7NyJMeta || {},
    alias: banner_45ad1nyBlP7NyJMeta?.alias || [],
    redirect: banner_45ad1nyBlP7NyJMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/banner-ad.vue").then(m => m.default || m)
  },
  {
    name: bannersCokovGSwVQMeta?.name ?? undefined,
    path: bannersCokovGSwVQMeta?.path ?? "/banners",
    meta: bannersCokovGSwVQMeta || {},
    alias: bannersCokovGSwVQMeta?.alias || [],
    redirect: bannersCokovGSwVQMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/banners.vue").then(m => m.default || m),
    children: [
  {
    name: addr2GRVIrz7yMeta?.name ?? "banners-add",
    path: addr2GRVIrz7yMeta?.path ?? "add",
    meta: addr2GRVIrz7yMeta || {},
    alias: addr2GRVIrz7yMeta?.alias || [],
    redirect: addr2GRVIrz7yMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/banners/add.vue").then(m => m.default || m)
  },
  {
    name: indexDh37q8uX7vMeta?.name ?? "banners",
    path: indexDh37q8uX7vMeta?.path ?? "",
    meta: indexDh37q8uX7vMeta || {},
    alias: indexDh37q8uX7vMeta?.alias || [],
    redirect: indexDh37q8uX7vMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/banners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93RbrEqeLW3qMeta?.name ?? "edit-id",
    path: _91id_93RbrEqeLW3qMeta?.path ?? "/edit/:id()",
    meta: _91id_93RbrEqeLW3qMeta || {},
    alias: _91id_93RbrEqeLW3qMeta?.alias || [],
    redirect: _91id_93RbrEqeLW3qMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: faq8k4SOvCxxfMeta?.name ?? "faq",
    path: faq8k4SOvCxxfMeta?.path ?? "/faq",
    meta: faq8k4SOvCxxfMeta || {},
    alias: faq8k4SOvCxxfMeta?.alias || [],
    redirect: faq8k4SOvCxxfMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexfvWSwy5au8Meta?.name ?? "index",
    path: indexfvWSwy5au8Meta?.path ?? "/",
    meta: indexfvWSwy5au8Meta || {},
    alias: indexfvWSwy5au8Meta?.alias || [],
    redirect: indexfvWSwy5au8Meta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyn06zuJUBoDMeta?.name ?? "privacy-policy",
    path: privacy_45policyn06zuJUBoDMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyn06zuJUBoDMeta || {},
    alias: privacy_45policyn06zuJUBoDMeta?.alias || [],
    redirect: privacy_45policyn06zuJUBoDMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileHSfglt6Qs5Meta?.name ?? undefined,
    path: profileHSfglt6Qs5Meta?.path ?? "/profile",
    meta: profileHSfglt6Qs5Meta || {},
    alias: profileHSfglt6Qs5Meta?.alias || [],
    redirect: profileHSfglt6Qs5Meta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexbaMhNVwJKMMeta?.name ?? "profile",
    path: indexbaMhNVwJKMMeta?.path ?? "",
    meta: indexbaMhNVwJKMMeta || {},
    alias: indexbaMhNVwJKMMeta?.alias || [],
    redirect: indexbaMhNVwJKMMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: likedxP0uXOxLVBMeta?.name ?? "profile-liked",
    path: likedxP0uXOxLVBMeta?.path ?? "liked",
    meta: likedxP0uXOxLVBMeta || {},
    alias: likedxP0uXOxLVBMeta?.alias || [],
    redirect: likedxP0uXOxLVBMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/profile/liked.vue").then(m => m.default || m)
  },
  {
    name: my_45ads81PUvuw1lCMeta?.name ?? "profile-my-ads",
    path: my_45ads81PUvuw1lCMeta?.path ?? "my-ads",
    meta: my_45ads81PUvuw1lCMeta || {},
    alias: my_45ads81PUvuw1lCMeta?.alias || [],
    redirect: my_45ads81PUvuw1lCMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/profile/my-ads.vue").then(m => m.default || m)
  }
]
  },
  {
    name: searchureOsyd8JAMeta?.name ?? "search",
    path: searchureOsyd8JAMeta?.path ?? "/search",
    meta: searchureOsyd8JAMeta || {},
    alias: searchureOsyd8JAMeta?.alias || [],
    redirect: searchureOsyd8JAMeta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: termsHY2OcZu6z6Meta?.name ?? "terms",
    path: termsHY2OcZu6z6Meta?.path ?? "/terms",
    meta: termsHY2OcZu6z6Meta || {},
    alias: termsHY2OcZu6z6Meta?.alias || [],
    redirect: termsHY2OcZu6z6Meta?.redirect,
    component: () => import("/home/orgio/projects/ad-portal/frontend/pages/terms.vue").then(m => m.default || m)
  }
]
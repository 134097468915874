import validate from "/home/orgio/projects/ad-portal/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import banner_45global from "/home/orgio/projects/ad-portal/frontend/middleware/banner.global.js";
import user_45global from "/home/orgio/projects/ad-portal/frontend/middleware/user.global.js";
import manifest_45route_45rule from "/home/orgio/projects/ad-portal/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  banner_45global,
  user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}